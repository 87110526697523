import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import DatePicker from "react-datepicker";
import { useAuth } from "../AuthContext";
// import { getAuth, signOut, isSignInWithEmailLink, signInWithEmailLink} from "firebase/auth";
import { sendSignInLinkToEmail } from '../firebaseConfig';
import Community from '../pages/Community';

import "react-datepicker/dist/react-datepicker.css";

const TourBookingForm = () => {
    const { currentUser} = useAuth();
    const [name, setName]= useState('');
    const [email, setEmail ] = useState('');
    const [guests, setGuests] = useState(1);
    const [reason, setReason] = useState('');
    const [date, setDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [bookingSaved, setBookingSaved] = useState(false);
    const navigate = useNavigate();

    // Redirect to login page if user is not logged in
    useEffect(() => {
        if (!currentUser) {
            navigate('/login');
        }
    }, [currentUser, navigate]);

    //Sign out current user
    // const handleSignOut = async () => {
    //     try {
    //         await signOut();
    //         alert("Sign-out successful!");
    //     } catch (error) {
    //         console.error("Error signing out: ", error);
    //         alert("Failed to sign out. Please try again later")
    //     }
    // }

    // const handleSubmit = async(e) => {
    //     e.preventDefault();
    //     //Prepare data to be sent
    //     const bookingInfo = {
    //         name,
    //         email,
    //         guests,
    //         date,
    //         reason,
    //         startDate
    //     };
    //     const db = getFirestore();
    //     const userDocRef = doc(db, "users", currentUser.uid);

    //     try {
    //         await setDoc(userDocRef, { bookingInfo }, { merge: true });
    //         // Send data to Zapier webhook
    //         fetch('https://hooks.zapier.com/hooks/catch/15944560/398ep9j/', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 name: bookingInfo.name,
    //                 email: bookingInfo.email,
    //                 guests: bookingInfo.guests,
    //                 date: bookingInfo.date,
    //                 reason:bookingInfo.reason,
    //                 startDate:bookingInfo.startDate
    //             })
    //         });
    //         setBookingSaved(true);
    //     } catch (error) {
    //         console.error("Error saving booking info:", error);
    //         alert("Failed to save booking info. Please try again later.");
    //     }

    //     //Send sign-in link to email
    //     try { 
    //         await sendSignInLinkToEmail(email);
    //         alert("Sign-in link sent to email!");
    //     } catch (error) {
    //         console.error("Error sending sign-in link", error);
    //         alert("Failed to send sign-in link. Please try again later");
    //     }
    // };

    // const handleSignInWithEmailLink = async () => {
    //     const auth = getAuth();
    //     const email = window.localStorage.getItem("emailForSignIn");

    //     if (email && isSignInWithEmailLink(window.location.href)) {
    //         try {
    //             await signInWithEmailLink(auth, email, window.location.href);
    //             window.localStorage.removeItem("emailForSignIn");
    //             alert("Sign-in successful!");
    //         } catch (error) {
    //             console.error("Error signing in with email link:", error);
    //             alert("Failed to sign in with email link. Please try again later.");
    //         }
    //     }
    // };

    // //Call the handleSignInWithEmailLink function when component mounts
    // useEffect(() => {
    //     handleSignInWithEmailLink();
    // }, []);

    if (bookingSaved) {
        return <Navigate to="/booking-confirmation" />;
    }

    return ( 
        <section className="py-5 text-center container">   
            <div className="row py-lg-5">
                <div className="col-lg-6 col-md-8 mx-auto">
                    <h1 className="display-1 page-title">Travel</h1>
                    <p className="lead text-body-secondary">
                        <p> {currentUser } Explore and connect with different cultures through Unieros travel experiences. Let our local city guides take to off-the-beaten path creative cities around the world.
                        </p>
                    </p>
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <img className="img-fluid" src="https://unieros.com/global/wp-content/uploads/2023/07/B0D836F2-9C1A-4E1A-89A7-44675CB32045_1_105_c.jpeg"  alt="Downtown Kingston Jamaica colorful mural We are Jamaicans"/>
                            <h2 className="display-3"> Kingston Canvas: A Creative Voyage</h2>
                            <p>Kingston is a vibrant creative city. On this tour you will get a introductory taste of Kingston's music, food and art districts.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <Community />
                        </div>
                    </div>
                    <div className="accordion" id="tourAccordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <p className="lead">Day 1: Welcome to Kingston</p>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#tourAccordion">
                                <div className="accordion-body">
                                    <p className="lead">Morning:</p>
                                    <p>Breakfast: Relish traditional Jamaican breakfast dishes, particularly the famous ackee and saltfish. Average meal price: <span className="small">$25 per person.</span></p>

                                    <p className="lead">Afternoon:</p>
                                    <p>Bob Marley Museum: Start your journey with a visit to this museum located at the former home of the reggae legend. It offers an insightful look into his life and the evolution of reggae music. <span className="small">Approx. $25 per person.</span></p>
                                    <p className="lead">Evening:</p>
                                    <p>Dance the Night Away: Visit a local dancehall or reggae bar. Kingston has several of them with live bands or DJs spinning the latest hits. <span className="small">Approx. $15 per person.</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <p className="lead">Day 2: Kingston’s Art & Music</p>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#tourAccordion">
                                <div className="accordion-body">
                                    <p>Day 2: Delving Deep into Kingston’s Art & Music Scenes</p>
                                    <p className="lead">Morning:</p>
                                    <p>National Gallery of Jamaica: Immerse yourself in Jamaican art, spanning from the Taino times to contemporary pieces. <span className="small">Approx. $25 per person.</span></p>

                                    <p className="lead">Afternoon:</p>
                                    <p>Devon House: Dine at the Grog Shoppe and treat yourself to some of the best ice cream on the island at the famous Devon House I-Scream. <span className="small">$25 per person.</span></p>

                                    <p class="lead">Evening:</p>
                                    <p>Dinner at The Marketplace: This culinary hub offers a variety of restaurants. Try “Tracks and Records” for a mix of music and delectable Jamaican fusion dishes <span className="small">Approx. $15 per person.</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <p className="lead">Day 3: Modern Beats & Street Arts</p>
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#tourAccordion">
                                <div className="accordion-body">
                                    <p className="lead">Morning:</p>
                                    <p>Street Art Tour: Start with a walking tour in Kingston, known for its vivid murals and community-driven art initiatives.
                                    <span className="small">Approx. $25 per person.</span></p>

                                    <p className="lead">Afternoon:</p>
                                    <p>Ital food: Enjoy a Rasta vegan lunch in authentic local restaurant where you will experience the Rastfarian culture that originated in Jamaica. <span className="small">$25 per person.</span></p>

                                    <p className="lead">Evening:</p>
                                    <p>Dub Music: Nestled in the hills overlooking Kingston, this spot offers reggae rhythms in a serene, natural setting. Dance to dub beats or just relax and soak in the vibes.
                                    <span className="small">Approx. $15 per person.</span></p>
                                    <p className="small">Note: Ensure you wear comfortable shoes, as there's a lot of walking. We will provide bottles of water. Remember to bring open mind to immerse yourself in the culture. Jamaicans are friendly and always ready to share stories of their vibrant city.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="whats-included">
                        <h6 className="lead">What's Included</h6>
                        <ul>
                            <li>3 nights accomodation</li>
                            <li> 3 breakfasts</li>
                            <li>2 lunches</li>
                            <li>3 dinners</li>
                            <li>Transportation from Kingston airport</li>
                            <li>Transportation to daily activities</li>
                            <li>Museum entry fees</li>
                            <li>Local guide along the way</li>
                        </ul>
                    </section>
                    <section className="whats-excluded">
                        <h6 className="lead"> What's Excluded</h6>
                        <ul>
                            <li>Visas</li>
                            <li>Travel insurance</li>
                            <li>Airfare</li>
                            <li>Tips</li>
                        </ul>
                    </section>
                    <section className="col-sm-12 reservation">
                        <h4 className="display-6">Reserve Your Spot</h4>
                        <h5>From US $1100/person <br/> <span className="small">Excludes taxes & fees</span></h5>
                        <div>
                            {currentUser ? (
                                <div>
                                    <p> Logged in as: {currentUser.email }</p>
                                    {/* <button onClick={handleSignOut}>Sign out</button> */}
                                </div>
                            ) : (
                                <p>You are not logged in </p>
                            )}   
                            <form>
                                <label className="form-label">Full Name</label>
                                <input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" required />
                                <label className="form-label">Email</label>
                                <input type="email"  className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
                                <label className="form-label">How many travelers?</label>
                                <input type="number"  className="form-control"  value={guests} onChange={(e) => setGuests(e.target.value)} placeholder="Number of Guests" />
                                <label className="form-label">Tell us why you want to travel to Kingston</label>
                                <textarea placeholder="optional" className="form-control" value={reason}  onChange={(e) => setReason(e.target.value)}>

                                </textarea>
                                <h6>Select Dates</h6>
                                <DatePicker selected={startDate} onChange={date => setStartDate(date)} 
                                minDate={new Date()} />
                                <button className="btn btn-custom-primary" type="submit">Request to Book</button><br/>
                                <span className="small">Not ready to book? <br/>Get today 1-hour US$50 consultation that can be used towards a tour.</span>
                            </form>
                        </div>
                    </section>
                </div>
            </section>
        </section>
    );
}

export default TourBookingForm;