import React from 'react';
import { Card } from  'react-bootstrap';
import { Link } from 'react-router-dom';
// import firebaseIntances from "../firebaseConfig";
import Tours from './Tours';
import unierosGroupChicago from '../assets/images/unieros_group2012.jpg';
import jamaicaEvent from '../assets/images/Jamaica61_event.jpg';
import kingstonTour from '../assets/images/slider/kingston.jpg';


// const { db } = firebaseIntances;
function Events() {
  //Connect to firebase
  // useEffect(() => {
  //   const db = db.firestore();
  //   db.collection('events')
  //     .orderBy('date', 'asc')
  //     .get()
  //     .then((querySnapshot) => {
  // //       const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  // //       setEvents(data);
  // //     })
  // //     .catch((error) => {
  // //       console.log('Error getting events: ', error);
  // //     });
  // // }, []);

  // // const handleSearchChange = (event) => {
  // //   setSearchTerm(event.target.value);
  // // };

  // const filteredEvents = events.filter((event) => {
  //   return (
  //     event.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     event.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     event.title.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });
  document.title = 'Unieros Multicultural Events';

  return (
    <section className="py-5 text-center container">
      <div className="row py-lg-5">
        <div className="col-lg-6 col-md-8 mx-auto">
          <h1 className="display-1 page-title">Events</h1>
          <p className="lead text-body-secondary">
            Explore and connect with different cultures through Unieros events.
            From food tours to music festivals, you'll love the immersive and welcoming atmosphere.
          </p>
          <p className="mb-2">
            {/* <a href="#" className="btn btn-unieros my-2">Sign up</a> */}
          </p>
        </div>
      </div>
      <div className="container my-5">
        <div className="row mt-4">
          <div className="col-12">
            <h2>Unieros Multicultural Events</h2>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
            <Card className="h-100">
              <Card.Img variant="top" src={jamaicaEvent} alt="Jamaica Event" className="img-fluid rounded" />
              <Card.Body>
                <Card.Title>Latest Event: Tings A Gwaan, Savannah, Georgia</Card.Title>
                <Card.Text></Card.Text>
                <Link to={`/global/event-review-tings-a-gwaan-a-celebration-of-jamaican-caribbean-culture`} className="btn btn-unieros">View photos</Link>
              </Card.Body>
            </Card>
          </div>
          {/*Past the event data to the Event component*/}
          <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
            <Card className="h-100">
              <Card.Img variant="top" src={unierosGroupChicago} alt="Unieros Multicultural Events Archive" className="img-fluid rounded" />
              <Card.Body>
                <Card.Title>Archive of Unieros Multicultural Events</Card.Title>
                <Card.Text></Card.Text>
                <Link to={`/global/unieros-multicultural-events-celebrate-diversity-culture/`} className="btn btn-unieros">View photos</Link>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
            <Card className="h-100">
              <Card.Img variant="top" src={kingstonTour} alt="Unieros Tour Kingston Jamaica" className="img-fluid rounded" />
              <Card.Body>
                <Card.Title>Kingston, Jamaica Tour</Card.Title>
                <Card.Text></Card.Text>
                <Link to="/tours" className="btn btn-unieros">Book now</Link>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row my-5">
          
          <div className="col-12">
       
          </div>
        </div>
      </div>
    </section>
  );
};

export default Events;