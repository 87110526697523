import React from 'react';
import { Card } from 'react-bootstrap';
import internationalTravel from '../assets/images/group_mural2.png'

import "react-datepicker/dist/react-datepicker.css";

function TourBooking() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mb-4">
          <h2 className="display-2">Kingston 3-day Tour</h2>
          <p>
            Kingston is the creative capital of Jamaica. This tour gives you an intro to the art scene, reggae music scene, and I-Tal Rasta food scene. Each day you will get a different cultural aspect of Jamaica.
          </p>
          <span className="small">Daily activities subject to change based on time of year</span>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 mb-4">
          <Card>
            <div className="youtube-video-container mb-4">
              <div className="video-responsive">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Pr9-5EWCTX0?si=mF_P0OgeenMsR-Jt"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <Card.Body>
              <Card.Title className="peach">
                <i className="bi bi-airplane-engines"></i> Travel
              </Card.Title>
              <section className="col-12 mb-4">
                <div className="accordion py-3" id="tourAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                         <p className="lead">Day 1: Welcome to Kingston</p>
                    </button>
                  </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#tourAccordion">
                  <div className="accordion-body">
                  <p className="lead">Morning:</p>
                                <p>Breakfast: Relish traditional Jamaican breakfast dishes, particularly the famous ackee and saltfish.</p>
                            
                                <p className="lead">Afternoon:</p>
                                <p>Bob Marley Museum: Start your journey with a visit to this museum located at the former home of the reggae legend. It offers an insightful look into his life and the evolution of reggae music. </p>
                                <p className="lead">Evening:</p>
                                <p>Dance the Night Away: Visit a local dancehall or reggae bar. Kingston has several of them with live bands or DJs spinning the latest hits. </p>
                                <div className="youtube-video-container" style={{marginBottom: '20px'}}>
                                    <div className="video-responsive">
                                    <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/zIubIYESXeo?si=r8u7nabGhNdw5YIc"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                    ></iframe>
                                </div>
                                </div>
                  </div>
                </div>
                </div>
                <div className="accordion-item">
                  <h2 class="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <p className="lead">Day 2: Kingston’s Art & Music</p>
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#tourAccordion">
                    <div className="accordion-body">
                  
                                  <p class="lead">Morning:</p>
                                  <p>National Gallery of Jamaica: Immerse yourself in Jamaican art, spanning from the Taino times to contemporary pieces. </p>
                                  
                                  <p class="lead">Afternoon:</p>
                                  <p>Devon House: Dine at the Grog Shoppe and treat yourself to some of the best ice cream on the island at the famous Devon House I-Scream. </p>
                                  
                                  <p class="lead">Evening:</p>
                                  <p>Dinner at The Marketplace: This culinary hub offers a variety of restaurants. Try “Tracks and Records” for a mix of music and Jamaican fusion dishes </p>
                                  <div className="youtube-video-container" style={{marginBottom: '20px'}}>
                     <div className="video-responsive">
                            <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/JMXTRrxmjJA?si=tE9gk5fzWWgE4lIg"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>

                            </iframe>
                    </div>
                 </div>

                                  
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <p className="lead green">Day 3: Modern Beats & Street Arts</p>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#tourAccordion">
                    <div class="accordion-body">
                    
                    
                                  <p class="lead">Morning:</p>
                                  <p>Street Art Tour: Start with a walking tour in Kingston, known for its vivid murals and community-driven art initiatives.
              </p>
                                  
                                  <p class="lead">Afternoon:</p>
                                  <p>Ital food: Enjoy a Rasta vegan lunch in authentic local restaurant where you will experience the Rastfarian culture that originated in Jamaica. </p>
                                  
                                  <p class="lead">Evening:</p>
                                  <p>Dub Music: Nestled in the hills overlooking Kingston, this spot offers reggae rhythms in a serene, natural setting. Dance to dub beats or just relax and soak in the vibes.
              </p>
              <div className="youtube-video-container" style={{marginBottom: '20px'}}>
                                    <div className="video-responsive">
                                    <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/FJRLxfd2W9U?si=eU0l_PFGgbjioC9S"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                    ></iframe>
                                </div>
                                </div>
              

          
                  </div>
                
                </div>
              </div>
              </div>
    </section>
    <section className="tourAmeneties">

    <div className="whats-included">
        <h6 className="lead">What's Included</h6>
        <ul>
            <li>2 lunches</li>
            <li>3 dinners</li>
            <li>Roundtrip transportation to and from Kingston airport</li>
            <li>Transportation to daily activities</li>
            <li>Bottled water</li>
            <li>Museum entry fees</li>
            <li>Local guide along the way</li>
        </ul>
    </div>
    <div className="whats-excluded">
        <h6 className="lead"> What's Excluded</h6>
        <p>We have recommendations for the following items but they are not included in package.</p>
        <ul>
            <li>Lodging</li>
            <li>Airfare</li>
            <li>Tips</li>
        </ul>
        </div>
        <p className="small">Note: Ensure you wear comfortable shoes, as there's a lot of walking. We will provide bottles of water. Remember to bring open mind to immerse yourself in the culture. Jamaicans are friendly and always ready to share stories of their vibrant city.</p>
    
    </section>
    <section>
 

</section>
<Card.Img variant="top" src={internationalTravel} />

        <h5 class="display-5">Want to learn more about this tour?</h5>
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLSdbYbFwJvMUeBOLtkia-ReOZXXDaQlp1o5chTLdF122SmnB-g/viewform" variant="primary" className="btn btn-unieros">Book Tour Info Session 
                      </a>
                    </Card.Body>
                    </Card>
  </div>                 
  </div>  
        </div>
    );
}

export default TourBooking;