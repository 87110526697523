import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import NewsletterSignup from '../components/NewsletterSignUp';
import './Blog.css'; // Import custom CSS for additional styling

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log('API URL:', process.env.REACT_APP_API_URL);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (response.headers.get("content-type").includes("application/json")) {
          const data = await response.json();
          setPosts(data);
        } else {
          throw new Error("Received non-JSON response");
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError(error);
      }
    };

    fetchPosts();
  }, [apiUrl]);

  useEffect(() => {
    if (posts.length > 0) {
      document.title = `Unieros - Blog - ${posts[posts.length - 1].title.rendered}`;
    }
  }, [posts]);

  const postsList = posts.filter((post) => post.type === 'post' || post.type === 'podcast');

  const getFeaturedImageUrl = (post) => {
    if (post._embedded && post._embedded['wp:featuredmedia']) {
      return post._embedded['wp:featuredmedia'][0].source_url;
    }
    return ''; 
  };

  return (
    <div className="container blog-container">
      <div className="row">
        {error ? (
          <p className="text-danger">Error loading posts: {error.message}</p>
        ) : (
          <section className="py-5">
            <div className="row">
              {postsList.map((post) => (
                <div key={post.id} className="col-md-6 col-lg-4 mb-4">
                  <div className="card h-100">
                    <img
                      src={getFeaturedImageUrl(post)}
                      alt="Featured blog visual"
                      className="card-img-top img-fluid rounded"
                    />
                    <div className="card-body">
                      <h3 className="card-title">{post.title.rendered}</h3>
                      <div
                        className="card-text"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.excerpt.rendered) }}
                      />
                      <Link to={`/global/${post.slug}/`} className="btn btn-unieros">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="newsletter-signup">
                  <NewsletterSignup />
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default Blog;